$podloga: #dcf9fa;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin centriraj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.holder {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $podloga;
}

.okvir {
  min-height: 100vh;
  width: 100vw;
  background-color: $podloga;
  border: 1px solid $podloga;
  position: relative;
  
  .naslov {
    font-size: 30px;
    margin-top: 150px;
    margin: 150px auto 10px auto;
    text-align: center;
  }

  .buffer-okvir {
    width: 100%;
    height: 60px;
  }
}

