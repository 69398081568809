@mixin centriraj {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin gumb {
    .div-button {
        height: 40px;
        width: 150px;
        @include centriraj;
        background-color: #bbb;
        margin: 0px 20px 0px 20px;
        transition: all 0.7s;
        color: black;
        border-radius: 4px;
  
        &:hover {
          cursor: pointer;
          background-color: #666;
          color: white;
        }
    }
}