
/* flash styling*/

.flash-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    /*z-index: -1;*/
    pointer-events: none;
    /*background-color: yellow;*/
  
  
  .alert {
    /*display: flex;*/  /*saltamo izmedu none i flex*/
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    z-index: 6;
    position: sticky;
    top: 0;
    pointer-events: auto;
  }
  
  .alert-close {
    /*background-color: yellow;*/
    height: 20px;
    width: 20px;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  
  .alert-close-el1 {
    background-color: black;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    transform: rotate(45deg);
  }
  
  .alert-close-el1:hover {
    background-color: #777;
    cursor: pointer;
  }
  
  .alert-close-el2 {
    background-color: white;
    height: 65%;
    width: 12%;
    position: absolute;
    top: 17.5%;
    left: 44%;
    /*z-index: 6;*/
  }
  
  .alert-close-el3 {
    background-color: white;
    height: 12%;
    width: 65%;
    position: absolute;
    top: 44%;
    left: 17.5%;
    /*z-index: 6;*/
  }
  
  .alert > p {
    margin: 10px 30px 10px 30px;
  }
  
  .alert-success {
    background-color: rgba(88,255,79,0.5);
    display: flex;
  }
  
  .alert-danger {
    background-color: rgba(242, 12, 43,0.5);
    display: flex;
  }
  
  .alert-invisible {
    display: none;
  }
  
  }