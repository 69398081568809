
$podloga: #dcf9fa;
$crna1: black;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.holder {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $podloga;
}

@mixin centriraj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.doprinosi-izbornik {
  min-height: 100vh;
  width: 100vw;
  background-color: $podloga;
  border: 1px solid $podloga;
  box-sizing: border-box;
  position: relative;

  .el {
    margin: 150px auto 0px auto;
    height: 120px;
    width: 100vw;
    position: relative;
    @include centriraj;

    .input-div {
      .input-div1 {
        font-size: 20px;
      } 
    }

    .input-el {
        padding: 5px;
        font-size: 19px;
        display: inline-block;
        /*color: green;*/
        margin-top: 10px;
        border: 2px solid grey;
        border-radius: 4px;
      }
  }

  .el1 {
    height: 170px;
    width: 100vw;
    position: relative;
    @include centriraj;
    flex-direction: column;

    .checkbox-opcije {
      @include centriraj;
      width: 100%;
      height: 40px;
    }

    .select-opcije {
      height: 130px;
      width: 100%;
      @include centriraj;

      @media screen and (max-width: 550px) {
        & {
          flex-direction: column;
          height: 260px;
        }
      }
    }

    .izbornik {
      width: 300px;
      margin: 10px 10px 10px 10px;
    }

    @media screen and (max-width: 550px) {
      & {
        flex-direction: column;
        height: 260px;
      }
    }

  }

  .el2 {
    height: 100px;
    width: 100vw;
    @include centriraj;

    .div-button {
      height: 40px;
      width: 150px;
      @include centriraj;
      background-color: #bbb;
      margin: 0px 20px 0px 20px;
      transition: all 0.7s;
      color: black;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: #666;
        color: white;
      }
    }

    .klik {
      background-color: red;
      transition: all 0s;

      &:hover {
        background-color: red;
      }
    }
  }
}

.pridruzi-doprinos-popup {
  height: 100vh;
  width: 100vw;
  @include centriraj;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0,0,0,0.6);
  cursor: initial;

  .okvir-menu {
    width: 400px;
    height: 440px;
    max-width: 95vw;
    background-color: $podloga;
    border: 3px solid grey;
    position: relative;

    .spinner {
      z-index: 2;
    }

    .gumbi {
      /*background-color: red;*/
      width: 100%;
      height: 40px;
      @include centriraj;


      .div-button-1 {
        height: 30px;
        width: 110px;
        @include centriraj;
        background-color: #bbb;
        margin: 0px 20px 0px 20px;
        transition: all 0.7s;
        color: black;
        border-radius: 4px;
        user-select: none;
  
        &:hover {
          cursor: pointer;
          background-color: #64c5fa;
          color: white;
        }
      }

      .klik-1 {
        background-color: red;
        transition: all 0s;

        &:hover {
          background-color: red;
        }
      }

    }

    .krizic {
      height: 30px;
      width: 30px;
      @include centriraj;
      position: absolute;
      right: 10px;
      top: 10px;
      /*z-index: 10;*/
      background-color: white;
      border-radius: 50%;
      transition: all 0.4s;

      .krizic-el {
        height: 60%;
        width: 60%;
        transform: rotate(45deg);
        
        .el1a {
          position: absolute;
          height: 22%;
          width: 100%;
          top: 39%;
          left: 0%;
          background-color: black;
          transition: all 0.4s;
        }
  
        .el2a {
          position: absolute;
          height: 100%;
          width: 22%;
          top: 0%;
          left: 39%;
          background-color: black;
          transition: all 0.4s;
        }
  
      }

      &:hover {
        cursor: pointer;
        background-color: #ccc;

        .krizic-el {
          .el1a {
            background-color: #026270;
          }
          .el2a {
            background-color: #026270;
          }
        }
      }
    }

    .tablica {
      width: 300px;
      max-width: 90%;
      margin: 10px auto 0px auto;

      .row {
        width: 100%;
        transition: background-color 0.3s;
        
        .row-el1 {
          width: 55%;
          height: 30px;
          border: 1px dotted black;
          padding: 0px 7px 0px 7px;
          overflow: visible;
        }
        .row-el2 {
          width: 44%;
          height: 30px;
          border: 1px dotted black;
          text-align: center;
          overflow: visible;
        }
      }
    }
    .kalendar-cont {
      width: 100%;
      /*background-color: yellow;*/
      display: flex;
      flex-direction: row;

      .lijeva-strana {
        /*background-color: pink;*/
        height: 100%;
        width: 200px;

        .el-a {
          margin: 0px auto 0px auto;
          height: 180px;
          width: 100%;
          /*background-color: yellow;*/
          @include centriraj;
      
          .input-div-a {
            .input-div1-a {
              /*background-color: red;*/
              font-size: 12px;
            } 
          }
      
          .input-el-a {
              padding: 5px;
              font-size: 15px;
              display: inline-block;
              /*color: green;*/
              margin-top: 10px;
              border: 2px solid grey;
              border-radius: 4px;
              max-width: 150px;
            }
        }
      
        .el1-a {
          height: 130px;
          width: 100%;
          /*background-color: red;*/
          @include centriraj;

          .no-click {
            pointer-events: none;
          }
      
          .izbornik-a {
            width: 150px;
            
            .span-el-a {
              display: inline-block;
              min-width: 70px;
            }
          }
      
        }
      }
    }
  }
}