$podloga: #dcf9fa;
$crna1: black;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.holder {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $podloga;
}

@mixin centriraj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vozilaIzbornik {
  min-height: 100vh;
  width: 100vw;
  background-color: $podloga;
  border: 1px solid $podloga;
  box-sizing: border-box;
  position: relative;
  @include centriraj;

  .vozila-izbornik-desna {
    min-height: 100vh;
    width: 50%;

    .buffer {
      height: 150px;
      width: 100%;
    }
  }

  .vozila-izbornik-lijeva {
    width: 50%;
    
    .el {
      margin: 170px auto 0px auto;
      height: 150px;
      width: 100%;
      /*background-color: yellow;*/
      @include centriraj;

      .input-div {
        .input-div1 {
          font-size: 20px;
        } 
      }

      .input-el {
        padding: 5px;
        font-size: 19px;
        display: inline-block;
        /*color: green;*/
        margin-top: 10px;
        border: 2px solid grey;
        border-radius: 4px;
      }
    }

    .el1 {
      height: 150px;
      width: 100%;
      /*background-color: pink;*/
      @include centriraj;

      .izbornik {
        width: 300px;
      
        .span-el {
          display: inline-block;
          min-width: 70px;
        }
      }

    }

    .el2 {
      height: 100px;
      width: 100%;
      @include centriraj;

      .div-button {
        height: 40px;
        width: 150px;
        @include centriraj;
        background-color: #bbb;
        margin: 0px 20px 0px 20px;
        transition: all 0.7s;
        color: black;
        border-radius: 4px;

        &:hover {
          cursor: pointer;
          background-color: #666;
          color: white;
        }
      }
    }
  }

  @media screen and (max-width: 840px) {
    & {
      flex-direction: column;

      .vozila-izbornik-lijeva {  
        width: 100%;  
      }

      .vozila-izbornik-desna {  
        width: 100%;  
        .buffer {
          height: 0;
        }
      }
    }
  }
}

.vozaciIzbornik {
  min-height: 100vh;
  width: 100vw;
  background-color: $podloga;
  border: 1px solid $podloga;
  box-sizing: border-box;
  position: relative;
  @include centriraj;

  .vozaci-izbornik-desna {
    min-height: 100vh;
    width: 50%;
    /*background-color: blue;*/

    .buffer {
      height: 150px;
      width: 100%;
    }
  }

  .vozaci-izbornik-lijeva { 
    /*min-height: 100vh;*/
    width: 50%;
    /*background-color: green;*/

    .el {
      margin: 170px auto 0px auto;
      height: 150px;
      width: 100%;
      /*background-color: yellow;*/
      @include centriraj;

      .input-div {
        .input-div1 {
          /*background-color: red;*/
          font-size: 20px;
        } 
      }

      .input-el {
        padding: 5px;
        font-size: 19px;
        display: inline-block;
        /*color: green;*/
        margin-top: 10px;
        border: 2px solid grey;
        border-radius: 4px;
      }
    }

    .el1 {
      height: 150px;
      width: 100%;
      @include centriraj;
      flex-direction: column;

      .checkbox-opcije {
        height: 40px;
        width: 100%;
        @include centriraj;

        .checkbox-div {
          margin: 0px 10px 0px 10px;
        }
      }

      .izbornik {
        width: 300px;
      }
    }

    .el2 {
      height: 100px;
      width: 100%;
      @include centriraj;
      /*background-color: blue;*/

      .div-button {
        height: 40px;
        width: 150px;
        @include centriraj;
        background-color: #bbb;
        margin: 0px 20px 0px 20px;
        transition: all 0.7s;
        color: black;
        border-radius: 4px;

        &:hover {
          cursor: pointer;
          background-color: #666;
          color: white;
        }
      }
    }
  } 
 
  @media screen and (max-width: 840px) {
    & {
      flex-direction: column;

      .vozaci-izbornik-lijeva {  
        width: 100%;  
      }

      .vozaci-izbornik-desna {  
        width: 100%;  
        .buffer {
          height: 0;
        }
      }
    }
  }
}

.popup-plast-confirm { //ovo je za signup formu
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  @include centriraj;

.popup-confirm {
  height: 450px;
  width: 500px;
  max-width: 94vw;
  background-color: $crna1;
  margin: 100px auto 0px auto;
  position: relative;
  border: 1px solid black;

  .spinner {  // overridan spinner za slucaj signout windowa
    left: 200px;
    top: 240px;
  }    

  .naslov {
    font-size: 25px;
    font-family: sans-serif;
    font-weight: 600;
    color: white;
    width: 80%;
    margin: 130px auto 0px auto;
    text-align: center;
    /*background-color: yellow;*/
  }

  .gumbi {
    /*background-color: yellow;*/
    width: 100%;
    @include centriraj;
    flex-direction: row;
    margin-top: 50px;

    .gumb {  // overidamo za slucaj gumba na signout windowu
      margin: 0px 50px 0px 50px;
    
      .gumb-ikona {
        color: white;
        height: 0px;
        width: 0px;
        margin-right: 0px;
      }
    }
  }
}
}