$crna1: black;
$crna2: #111;
$crna25: #191919;
$crna3: #222;

$siva1: #777;

$borderColor: #333;
$borderColor1: #777;

$visinaPoruke: 60px;

@mixin centriraj {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*spinner styling*/

@keyframes pulse {
  100% {
    transform: rotate(360deg);  
  }
}

.spinner {
  $boja1: $crna3;
  $boja2: $siva1;

  height: 100px;
  width: 100px;
  background-color: $boja1;
  overflow: hidden;
  border-radius: 50%;
  /*border: -2px solid green;*/
  position: absolute;
  left: 200px;
  top: 100px;
  box-sizing: border;

  animation: pulse 1s 0s linear infinite normal;

  .spinner-el {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $boja2;
    /*border: 0px solid green;*/
    /*position: relative;*/
    /*clip-path: polygon(0.00003316988341772742% -0.00003316986141310707%, 50% 50%, 99.99996683011658% -0.00003316986141310707)*/
  }

  .spinner-el1 {
    height: 80%;
    width: 80%;
    background-color: $boja2;
    position: absolute;
    top: 10%;
    left: 10%;
    z-index: 10;
    border-radius: 50%;
  }
}

@mixin krizic($vel: 25px, $udalj: 10px) {
    .lista-gumb {
      position: absolute;
      right: $udalj;
      top: $udalj;
      height: $vel;
      width: $vel;
      /*background-color: red;*/
      @include centriraj;
      overflow: hidden;
  
      &:hover {
        cursor: pointer;
        .lista-gumb-el {
        .lista-gumb-el1, .lista-gumb-el2 {
          background-color: #bbb;
        }
        }
      }
  
      .lista-gumb-el {
        height: 95%;
        width: 95%;
        /*background-color: green;*/
        position: relative;
        rotate: 45deg;
  
        .lista-gumb-el1 {
          position: absolute;
          top: 0;
          left: 40%;
          height: 100%;
          width: 20%;
          background-color: white;
          transition: all 0.4s;
        }
  
        .lista-gumb-el2 {
          position: absolute;
          top: 40%;
          left: 0;
          height: 20%;
          width: 100%;
          background-color: white;
          transition: all 0.4s;
        }
      }
    }
  }

@mixin signup-forma {
    .lista-naslov {
      font-family: sans-serif;
      font-size: 24px;
      font-weight: 600;
      color: white;
      margin-top: 30px;
      /*background-color: yellow;*/
      @include centriraj;
    }
  
    .lista-div1 {
      width: 100%;
      height: 50px;
      /*background-color: yellow;*/
      //@include centriraj;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 20px;
  
      .user {
        height: 40px;
        width: 40px;
        /*position: absolute;
        left: 15px;
        top: 5px;*/
        margin-left: 25px;
        background-color: grey;
        @include centriraj;
        border-radius: 50%;
    
        &:hover {
          cursor: pointer;
        }
    
        .person-ikona {
          height: 70%;
          width: 70%;
        }
      }
    }
    .lista-div {
      /*background-color: yellow;*/
      margin: 30px auto 0px auto;
      display: block;
      width: 80%;
      height: 60px;
      color: white;
      font-family: sans-serif;
  
      label {
        font-weight: 600;
      }
        
      .input1 {
        height: 30px;
        font-size: 22px;
        width: 98%;
        color: white;
        /*background-color: red;*/
        margin-top: 8px;
        background-color: $crna3;
        /*margin: 40px auto 0px auto;*/
        /*display: block;*/
        border: 0px solid black;
  
        &:focus {
          border: 0px solid black;
          outline: none;
        }
      }
  
    }
  }

  .gumb {
    /*width: 80px;
    height: 50px;*/
    /*background-color: #d9e6fa;*/
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    user-select: none;
    /*transition: all 0.5s;*/
    margin: 0px 20px 0px 20px;
    color: white;
  
    .gumb-ikona {
      color: white;
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }
  
  .gumb-nekliknuti {
    transition: all 0.5s;
    /*background-color: #d9e6fa;*/
    background-color: #11a892;
  }
  
  .gumb-kliknuti {
    background-color: red;
  }
  
  .gumb-nekliknuti:hover {
    /*background-color: #d2fcc7;*/
    background-color: #047061;
    cursor: pointer;
  }

.signin {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  @include centriraj;

  .signin-ploca {
    height: 450px;
    width: 500px;
    max-width: 90vh;
    background-color: black;
    border: 10px;
    position: relative;

    @include krizic($vel: 20px, $udalj: 10px);
    @include signup-forma;

    .forgot-password-div {
      height: 60px;
      width: 100%;
      margin-top: 15px;
      position: relative;

      .forgot-password-ikona {
        height: 50px;
        width: 50px;
        color: grey;
        /*margin: 5px 0px 0px 18px;*/
        position: absolute;
        top: 5px;
        left: 18px;
        
        &:hover {
          cursor: pointer;
        }
    
      }

      .div-natpis {
        height: 30px;
        width: 100px;
        position: absolute;
        top: 15px;
        left: 70px;
        color: #aaa;
        font-family: sans-serif;
        font-size: 12px;
        font-weight: 600;
        @include centriraj;
      }
    }

  }
}

.popup-plast { //ovo je za signup formu
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  @include centriraj;

  .forgot-password {
    height: 300px;
    width: 370px;
    max-width: 95vw;
    background-color: $crna1;
    margin: 20px auto 0px auto;
    background-color: $crna1;
    border: 1px solid black;
    position: relative;

    .spinner {
      top: 100px;
      left: 140px;
    }

    .gumbi {
      height: 60px;
      width: 100%;
      @include centriraj;

      .user {
        height: 40px;
        width: 40px;
        margin-left: 25px;
        background-color: grey;
        @include centriraj;
        border-radius: 50%;
    
        &:hover {
          cursor: pointer;
        }
    
        .person-ikona {
          height: 70%;
          width: 70%;
        }
      }
    }

    .lista-naslov {
      font-family: sans-serif;
      font-size: 16px;
      font-weight: 600;
      color: white;
      margin: 30px 30px 0px 30px;
      @include centriraj; 
    }

    .forgot-password-p1 {
      color: white;
      font-size: 14px;
      margin: 20px 15px 20px 15px;
    }

    .lista-div {
      /*background-color: yellow;*/
      margin: 30px auto 0px auto;
      display: block;
      width: 80%;
      height: 60px;
      color: white;
      font-family: sans-serif;
  
      label {
        font-weight: 600;
      }
        
      .input1 {
        height: 29px;
        font-size: 20px;
        width: 98%;
        color: white;
        margin-top: 8px;
        background-color: $crna3;
        border: 0px solid black;
  
        &:focus {
          border: 0px solid black;
          outline: none;
        }
      }
  
    }
  }

  .popup-activation-info {
    height: 300px;
    width: 370px;
    max-width: 95vw;
    background-color: $crna1;
    margin: 20px auto 0px auto;
    background-color: $crna1;
    border: 1px solid black;
    @include centriraj;

    .obavijest {
      font-size: 22px;
      font-weight: 600;
      margin: 0px 30px 0px 30px;
      color: white;
    }
  }
  
  .popup-signup {
    height: 570px;
    width: 500px;
    max-width: 94vw;
    background-color: $crna1;
    margin: 100px auto 0px auto;
    position: relative;
    border: 1px solid black;

    .spinner {  // overridan spinner za slucaj signup windowa
      left: 200px;
      top: 210px;
    }  

    @include krizic($vel: 20px, $udalj: 10px);

    //@include signup-forma;
    .lista-naslov-1 {
      font-family: sans-serif;
      font-size: 24px;
      font-weight: 600;
      color: white;
      margin-top: 30px;
      /*background-color: yellow;*/
      @include centriraj;
    }
  
    .lista-div1-1 {
      width: 100%;
      height: 50px;
      /*background-color: yellow;*/
      //@include centriraj;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 20px;
  
      .user-1 {
        height: 40px;
        width: 40px;
        /*position: absolute;
        left: 15px;
        top: 5px;*/
        margin-left: 25px;
        background-color: grey;
        @include centriraj;
        border-radius: 50%;
    
        &:hover {
          cursor: pointer;
        }
    
        .person-ikona-1 {
          height: 70%;
          width: 70%;
        }
      }
    }
    .lista-div-1 {
      /*background-color: yellow;
      border: 2px solid blue;*/
      margin: 22px auto 0px auto;
      display: block;
      width: 80%;
      height: 55px;
      color: white;
      font-family: sans-serif;
  
      label-1 {
        font-weight: 600;
      }
        
      .input1-1 {
        height: 27px;
        font-size: 18px;
        width: 98%;
        color: white;
        /*background-color: red;*/
        margin-top: 8px;
        background-color: $crna3;
        /*margin: 40px auto 0px auto;*/
        /*display: block;*/
        border: 0px solid black;
  
        &:focus {
          border: 0px solid black;
          outline: none;
        }
      }
  
    }
  }

  .popup-signout {
    height: 450px;
    width: 500px;
    max-width: 94vw;
    background-color: $crna1;
    margin: 0px auto 0px auto;
    position: relative;
    border: 1px solid black;

    .spinner {  // overridan spinner za slucaj signout windowa
      left: 200px;
      top: 240px;
    }    

    .naslov {
      font-size: 25px;
      font-family: sans-serif;
      font-weight: 600;
      color: white;
      width: 80%;
      margin: 130px auto 0px auto;
      text-align: center;
      /*background-color: yellow;*/
    }

    .gumbi {
      /*background-color: yellow;*/
      width: 100%;
      @include centriraj;
      flex-direction: row;
      margin-top: 50px;

      .gumb {  // overidamo za slucaj gumba na signout windowu
        margin: 0px 50px 0px 50px;
      
        .gumb-ikona {
          color: white;
          height: 0px;
          width: 0px;
          margin-right: 0px;
        }
      }
    }
  }

  .popup-obavijest {
    height: 400px;
    width: 450px;
    max-width: 94vw;
    background-color: $crna1;
    /*background-color: red;*/
    margin: 100px auto 0px auto;
    position: relative;
    border: 1px solid black;
    @include centriraj;

    .p-el {
      font-size: 20px;
      color: white;
      font-weight: 600;
      text-align: center;
      margin: 0px 20px 0px 20px;
    }
  }

  .popup-verification {
    height: 400px;
    width: 450px;
    max-width: 94vw;
    background-color: $crna1;
    /*background-color: red;*/
    margin: 100px auto 0px auto;
    position: relative;
    border: 1px solid black;

    .lista-naslov {
      font-family: sans-serif;
      font-size: 16px;
      /*font-weight: 600;*/
      color: white;
      margin: 30px 30px 0px 30px;
      /*background-color: yellow;*/
      @include centriraj;

      
    }

    .lista-div {
      /*background-color: green;*/
      height: 100px;
      width: 90%;
      margin: 20px auto 0px auto;
      @include centriraj;
      flex-direction: column;

      .input {
        height: 30px;
        font-size: 22px;
        width: 98%;
        color: white;
        /*background-color: red;*/
        margin-top: 8px;
        background-color: $crna3;
        /*margin: 40px auto 0px auto;*/
        /*display: block;*/
        border: 0px solid black;
  
        &:focus {
          border: 0px solid black;
          outline: none;
        }
      }
    }

    .element {
      height: 70px;
      width: 100%;
      /*background-color: yellow;*/
      @include centriraj;

      .gumb1 {
        
          height: 40px;
          width: 130px;
          background-color: #bbb;
          border-radius: 4px;
          margin: 10px 10px 0px 10px;
          color: black;
          transition: all 0.7s;
          @include centriraj;
    
          &:hover {
            background-color: #999;
            color: #333;
            cursor: pointer;
          }
        
      }
    }

    .gumb {
      height: 40px;
      width: 130px;
      background-color: #bbb;
      border-radius: 4px;
      margin: 10px auto 0px auto;
      color: black;
      transition: all 0.7s;

      &:hover {
        background-color: #999;
        color: #333;
        cursor: pointer;
      }
    }
  }
}