$podloga: #dcf9fa;

$boja-kalendara: #999;
$boja-mjeseca-godine: white;
$boja-natpisa-dana: black;
$boja-nedjelje: red;
$boja-selected-circle: rgba(255, 255, 255, 0.4);
$hover-boja: rgba(255, 255, 255, 0.2);
$boja-drugog-mjeseca: #ccc;
$boja-hover-gumb: rgba(255, 255, 255, 0.5);

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
@mixin centriraj {
    display: flex;
    justify-content: center;
    align-items: center;
}

.kalendar-okvir {
    /*height: 450px;
    width: 400px;*/
    background-color: $boja-kalendara;
    position: relative;
    user-select: none;
    border-radius: 4px;

    .el1-kalendar {
      height: 11.111%;
      width: 100%;
      background-color: $boja-kalendara;
      position: absolute;
      top: 0;
      left: 0;
      /*font-size: 24px;*/
      font-weight: 600;

      .p-el-kalendar {
        color: $boja-mjeseca-godine;
      }
      
      .gumb1-kalendar, .gumb2-kalendar, .gumb3-kalendar, .gumb4-kalendar {
        /*height: 30px;
        width: 30px;*/
        position: absolute;
        /*top: 10px;
        left: 10px;*/
        /*background-color: aqua;*/
        @include centriraj;

        &:hover {
          cursor: pointer;
          background-color: $boja-hover-gumb;
        }
      }

      .ikona {
        height: 70%;
        width: 70%;
      }

      .mjesec-el {
        position: absolute;
        top: 0;
        left: 0;
        /*background-color: blue;*/
        height: 100%;
        width: 60%;
        @include centriraj;

        .gumb2 {
          left: initial;
          right: 0;
        }
      }
      .godina-el {
        position: absolute;
        top: 0;
        right: 0;
        /*background-color: green;*/
        height: 100%;
        width: 40%;
        @include centriraj;

        .gumb4 {
          left: initial;
          right: 0;
        }
      }
    }

    .el3-kalendar {
      /*font-size: 20px;*/
      font-weight: 600;
      position: absolute;
      top: 11.111%;
      left: 0;
      height: 11.111%;
      width: 100%;
      /*background-color: red;*/
      display: flex;
      flex-direction: row;

      .dani {
        height: 100%;
        width: 14.2857%;
        color: $boja-natpisa-dana;
        @include centriraj;
      }

      .ned {
        color: $boja-nedjelje;
      }
    }

    .el2-kalendar {
      height: 77.7777%;
      width: 100%;
      /*background-color: pink;*/
      position: absolute;
      bottom: 0;
      left: 0;

      .el-div {
        position: absolute;
        height: 16.6667%;
        width: 14.2857%;
        /*background-color: yellow;*/
        box-sizing: border-box;
        /*border: 2px solid grey;*/
        @include centriraj;

        .kruzic-no-hover {
          height: 75%;
          width: 75%;
          background-color: rgba(0,0,0,0.0);
          color: $boja-drugog-mjeseca;
          @include centriraj;
          border-radius: 50%;
        }

        .kruzic {
          height: 75%;
          width: 75%;
          background-color: rgba(0,0,0,0.0);
          @include centriraj;
          border-radius: 50%;

          &:hover {
            cursor:pointer;
            background-color: $hover-boja;
          }
        }

        .kliknuti {
          background-color: $boja-selected-circle;
          &:hover {
            background-color: $boja-selected-circle;
          }
        }
      }

      .nedjelja {
        color: $boja-nedjelje;
      }

    }
}
