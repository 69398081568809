
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
@mixin centriraj {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar {
    height: 150px;
    width: 100%;
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    transition: height 1s;
    overflow: hidden;
  
    &:hover {
      background-color: rgba(0,0,0,0.6);
    }

    .zastavice {
      position: absolute; 
      right: 30px;
      top: 20px;
      height: 15px;
      width: 68px;
      
      .zastava {
        position: absolute;
        height: 100%;
        opacity: 0.4;
        transition: opacity 0.7s;

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        .zastava {
          opacity: 1;
        }
      }

      .odabrana {
        outline: 2px solid white;
      }

      .croatia {
        top: 0;
        left: 0;
      }

      .britain {
        top: 0;
        right: 0;
      }

      .natpis {
        position: absolute;
        top: 160%;
        height: 30px;
        width: 110px;
        background-color: white;
        border-radius: 4px;
        background-color: #fac878;
        border: 1px solid grey;
        /*@include centriraj;*/
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 2;

        @media (hover: none) {
          & {
            display: none;
          }
        }
      }

      .hrvatski { left: 0; display: none;}
      .engleski { left: -60%; top: 160%;}
    }
  
    .link-traka {
      height: 60px;
      width: 600px;
      position: absolute;
      top: 45px;
      right: 20px;
      @include centriraj;
      justify-content: flex-end;
  
      @media screen and (max-width: 799px) {
        & {
            display: none;
        }
      }
  
      .link {
        margin-right: 20px;
        text-decoration: none;
        color: white;
  
        &:hover {
          color: #10c4c2;
        }
      }

      .link-div:hover {
        cursor: pointer;
        
      }

    }
  
    .link-traka1 {
      height: 300px;
      width: 100%;
      position: absolute;
      top: 150px;
      left: 0px;
      /*background-color: pink;*/
  
      @media screen and (min-width: 800px) {
        & {
            display: none;
        }
      }
  
      .link {
        display: block;
        color: white;
        height: 30px;
        width: 100%;
        text-decoration: none;
        /*background-color: pink;*/
        padding-left: 50px;
  
        &:hover {
          color: #10c4c2;
        }
      }

      .link-div:hover {
        cursor: pointer;
        
      }
    }
  
    .logo {
      max-height: 100px;
      position: absolute;
      top: 25px;
      left: 50px;
    }
  
    .gumb {
      height: 39px;
      width: 53px;
      background-color: #555;
      position: absolute;
      right: 30px;
      top: 53px;
      border-radius: 5px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
  
      @media screen and (min-width: 800px) {
        & {
            display: none;
        }
      }
  
      &:hover {
        cursor: pointer;
      }
  
      &:hover .crtica {
        background-color: #bbb;
      }
  
      .crtica {
        height: 12%;
        width: 75%;
        background-color: white;
        border-radius: 6px;
      }
    }
  
    .krizic {
      height: 30px;
      width: 30px;
      /*background-color: white;*/
      position: absolute;
      right: 45px;
      top: 55px;
      transform: rotate(45deg);
  
      @media screen and (min-width: 800px) {
        & {
            display: none;
        }
      }
  
      .el1 {
        position: absolute;
        height: 22%;
        width: 100%;
        top: 39%;
        left: 0%;
        background-color: white;
      }
  
      .el2 {
        position: absolute;
        height: 100%;
        width: 22%;
        top: 0%;
        left: 39%;
        background-color: white;
      }
  
      &:hover {
        cursor: pointer;
      }
  
      &:hover .el1, &:hover .el2 {
        background-color: #bbb;
      }
    }
  }