$podloga: #dcf9fa;

$parniRedak: #b3c9c9;
$neparniRedak: #95bdbd;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.holder {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $podloga;
}

@mixin centriraj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.okvir {
  min-height: 100vh;
  width: 100vw;
  background-color: $podloga;
  border: 1px solid $podloga;
  position: relative;
  
  .naslov {
    margin-top: 150px;
  }

  .naslov-obracun {
    margin-top: 150px;
    text-align: center;
    font-size: 30px;
  }

  .buffer {
    width: 100%;
    height: 80px;
  }
}

.tablica-obracun-cont {
  width: 100vw;
  /*min-height: 700px;*/

  .tablica-obracun {
    width: 500px;
    max-width: 95vw;
    position: relative;
    margin: 0px auto 0px auto;

    @media screen and (max-width: 500px) {
      & {
        width: 95vw;
      }
    }

    .spinner {
      top: 30%;
      left: 30%;
    }

    tr:nth-child(odd) {
      background-color: $neparniRedak;
    }

    tr:nth-child(even) {
      background-color: $parniRedak;
    }

    tr.hover {
      background-color: grey;
      cursor: pointer;
    }

    .hover-klasa {
      &:hover {
        background-color: grey;
        cursor: pointer;
      }
    }

    .row-obracun {
      width: 100%;
      transition: background-color 0.3s;
      
      .row-obracun-el1 {
        width: 33.33%;
        height: 30px;
        border: 1px dotted black;
        padding: 0px 7px 0px 7px;
        overflow: visible;
      }
      .row-obracun-el2 {
        width: 66.66%;
        height: 30px;
        border: 1px dotted black;
        text-align: center;
        overflow: visible;

        .obracun-polje {
          min-height: 130px;
          width: 240px;
          background-color: white;
          border: 3px solid grey;
          margin: 10px auto 10px auto;

          .redak {
            font-size: 12px;
            width: 100%;
            height: 15px;
            position: relative;

            .redak-el1 {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 60%;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .p-el {
                margin: 0px 10px 0px 0px;
              }
            }

            .redak-el2 {
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 40%;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .p-el1 {
                margin: 0px 10px 0px 0px;  
              }

              .p-el2 {
                margin: 0px 30px 0px 0px;  
              }
            }

            .zelena {
              color: green;
            }

            .crvena {
              color: red;
            }

            .crna {
              color: black;
            }
          }

          .crta {
            width: 100%;
            height: 5px;
            @include centriraj;

            .crta-el {
              width: 90%;
              height: 2px;
              background-color: black;
            }
          }
        }

        .checkbox-opcije {
          @include centriraj;
          margin: 5px 0px 5px 0px;
    
          .checkbox-div {
            margin: 0px 10px 0px 10px;
          }
        }

        .izbornik-b3 {
          width: 200px;
          font-size: 14px;

          .span-iznos {
            width: 72px;
            display: inline-block;
            text-align: right;
          }
        }

        .izbornik-b2 {
          width: 300px;
          font-size: 10px;

          .bez-odabira1 {
            font-size: 10px;
          }

          .span-el1 {
            display: inline-block;
            width: 130px;
          }

          .span-el2 {
            display: inline-block;
            width: 55px;
          }

          .span-el3 {
            display: inline-block;
            width: 90px;
          }

          .bez-odabira2 {
            font-size: 13px;
          }

          .span-el1a {
            display: inline-block;
            font-size: 13px;
            width: 170px;
          }

          .span-el2a {
            display: inline-block;
            font-size: 13px;
            width: 70px;
          }

          .bez-odabira4 {
            font-size: 14px;
          }

          .span-el1b {
            display: inline-block;
            font-size: 14px;
            width: 200px;
          }

          .bez-odabira3 {
            font-size: 11px;
          }

          .span-el1c {
            font-size: 11px;
            display: inline-block;
            width: 143px;
          }

          .span-el3c {
            font-size: 11px;
            display: inline-block;
            width: 100px;
          }

          @media screen and (max-width: 390px) {
            & {
              width: 250px;
              font-size: 8px;

              .span-el1 {
                display: inline-block;
                width: 105px;
              }
    
              .span-el2 {
                display: inline-block;
                width: 45px;
              }
    
              .span-el3 {
                display: inline-block;
                width: 85px;
              }
            }
          }
        }

        .ispis-prometa-okvir {
          height: 70px;
          width: 100%;
          @include centriraj;
          /*flex-direction: column;*/
          font-size: 15px;

          .okvir-lijevi {
            height: 100%;
            width: 50%;
            @include centriraj;
            flex-direction: column;
          }

          .okvir-desni {
            height: 100%;
            width: 50%;
            @include centriraj;
            flex-direction: column;
          }

          .okvir-desni1 {
            height: 100%;
            width: 50%;
            @include centriraj;

            .prikaz-biljeske {
              height: 80%;
              width: 90%;
              background-color: white;
              overflow: scroll;
              font-size: 12px;

              @media screen and (max-width: 500px) {
                & {
                  font-size: 11px;
                }
              }
      
              .biljeska-p {
                margin: 0px 4px 0px 4px;
                text-align: left;
              }
            }
          }

          .ispis-prometa {
            height: 30px;
            width: 100%;
            position: relative;
            @include centriraj;

            .ispis-prometa-el1 {
              height: 100%;
              width: 60%;
              position: absolute;
              top: 0;
              left: 0;
              @include centriraj;
            }

            .ispis-prometa-el2 {
              height: 100%;
              width: 40%;
              position: absolute;
              top: 0;
              right: 0;
              @include centriraj;
            }
          }
        }

        .korigirani-ispis-prometa {
          height: 110px;
          font-size: 14px;
        }

        @media screen and (max-width: 500px) {
          .ispis-prometa-okvir, .korigirani-ispis-prometa {
            font-size: 13px;
          }
        }

        @media screen and (max-width: 415px) {
          .ispis-prometa-okvir, .korigirani-ispis-prometa {
            font-size: 11px;
          }
        }

        .izbornik-b1 {
          width: 200px;
          max-width: 98%;
          font-size: 10px;
          margin: 10px auto 10px auto;
        }

        .kalendar-cont {
          min-height: 100%;
          width: 100%;
          @include centriraj;
          flex-direction: column;
          /*background-color: yellow;*/

          .div-button-2 {
            height: 30px;
            width: 110px;
            @include centriraj;
            background-color: #bbb;
            margin: 7px 15px 7px 15px;;
            transition: all 0.7s;
            color: black;
            border-radius: 4px;
            user-select: none;
      
            &:hover {
              cursor: pointer;
              background-color: #64c5fa;
              color: white;
            }
          }
      
          .klik-2 {
            background-color: red;
            transition: all 0s;
      
            &:hover {
              background-color: red;
            }
          }
        }

        .div-button {
          height: 30px;
          width: 80px;
          @include centriraj;
          background-color: #bbb;
          margin: 0px auto 0px auto;
          transition: all 0.7s;
          color: black;
          border-radius: 4px;
          user-select: none;
    
          &:hover {
            cursor: pointer;
            background-color: #64c5fa;
            color: white;
          }
        }

        .klik {
          background-color: red;
          transition: all 0s;

          &:hover {
            background-color: red;
          }
        }
      }
    }
  }
}

.vozaciIzbornik {
  min-height: 100vh;
  width: 100vw;
  background-color: $podloga;
  border: 1px solid $podloga;
  box-sizing: border-box;
  position: relative;
  @include centriraj;

  .vozaci-izbornik-desna {
    min-height: 100vh;
    width: 50%;

    .buffer {
      height: 150px;
      width: 100%;
    }

    .tablica-obracun-cont {
      width: 100%;
    }
  }

  .vozaci-izbornik-lijeva { 
    width: 50%;

    .el {
      margin: 170px auto 0px auto;
      height: 150px;
      width: 100%;
      /*background-color: yellow;*/
      @include centriraj;

      .input-div {
        .input-div1 {
          /*background-color: red;*/
          font-size: 20px;
        } 
      }

      .input-el {
        padding: 5px;
        font-size: 19px;
        display: inline-block;
        /*color: green;*/
        margin-top: 10px;
        border: 2px solid grey;
        border-radius: 4px;
      }
    }

    .el1 {
      height: 150px;
      width: 100%;
      @include centriraj;
      flex-direction: column;

      .checkbox-opcije {
        height: 40px;
        width: 100%;
        @include centriraj;

        .checkbox-div {
          margin: 0px 10px 0px 10px;
        }
      }

      .izbornik {
        width: 300px;
      }
    }

    .el2 {
      height: 100px;
      width: 100%;
      @include centriraj;
      /*background-color: blue;*/

      .div-button {
        height: 40px;
        width: 150px;
        @include centriraj;
        background-color: #bbb;
        margin: 0px 20px 0px 20px;
        transition: all 0.7s;
        color: black;
        border-radius: 4px;

        &:hover {
          cursor: pointer;
          background-color: #666;
          color: white;
        }
      }
    }
  } 
 
  @media screen and (max-width: 995px) {
    & {
      flex-direction: column;

      .vozaci-izbornik-lijeva {  
        width: 100%;  
      }

      .vozaci-izbornik-desna {  
        width: 100%;  
        .buffer {
          height: 0;
        }
      }
    }
  }
}