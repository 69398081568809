@import 'zajednicki';

$podloga: #dcf9fa;

$parniRedak: #b3c9c9;
$neparniRedak: #95bdbd;
$crna1: black;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.holder {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $podloga;
}

@mixin centriraj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.transakcije-izbornik {
    min-height: 100vh;
    width: 100vw;
    background-color: $podloga;
    border: 1px solid $podloga;
    box-sizing: border-box;
    position: relative;
    @include centriraj;

    .div-button {
      height: 34px;
      width: 100px;
      @include centriraj;
      font-size: 14px;
      background-color: #bbb;
      margin: 0px 15px 0px 15px;
      transition: all 0.7s;
      color: black;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: #666;
        color: white;
      }
    }

    .klik {
      background-color: red;
      transition: all 0s;

      &:hover {
        background-color: red;
      }
    }
  
    .prometi-izbornik-desna {
      min-height: 100vh;
      width: 50%;
  
      .buffer {
        height: 200px;
        width: 100%;
      }

      .button-cont {
        height: 50px;
        width: 100%;
        @include centriraj;
      }
    }
  
    .prometi-izbornik-lijeva { 
      min-height: 100vh;
      /*height: 1000px;*/
      width: 50%;
      box-sizing: border-box;
      position: relative;

      .antiklik-pokrivac {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        display: none;
      }
  
      .el {
        margin: 130px auto 0px auto;
        height: 100px;
        width: 100%;
        @include centriraj;
  
        .input-div {
          .input-div1 {
            /*background-color: red;*/
            font-size: 20px;
          } 
        }
  
        .input-el {
          padding: 5px;
          font-size: 19px;
          display: inline-block;
          /*color: green;*/
          margin-top: 10px;
          border: 2px solid grey;
          border-radius: 4px;
        }
      }

      .el1 {
        height: 240px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        .el {
          margin: 0px auto 0px auto;
          height: 100px;
          width: 100%;
          @include centriraj;
    
          .input-div {
            .input-div1 {
              /*background-color: red;*/
              font-size: 15px;
            } 
          }
    
          .input-el {
            padding: 5px;
            font-size: 19px;
            display: inline-block;
            /*color: green;*/
            margin-top: 10px;
            border: 2px solid grey;
            border-radius: 4px;
          }
        }
  
        .checkbox-opcije {
          height: 72px;
          width: 100%;
          
          .checkbox-redak {
            height: 35px;
            width: 100%;
            @include centriraj;

            .checkbox-div {
              margin: 0px 10px 0px 10px;
            }
          }
        }
  
        .izbornik {
          width: 300px;
          margin: 0px 0px 15px 0px;
        }

      }

      .el3 {
        height: 265px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .izbornik {
          width: 300px;
        }

        .el {
          margin: 0px auto 0px auto;
          height: 70px;
          width: 100%;
          @include centriraj;
    
          .input-div-a {
            .input-div1-a {
              /*background-color: red;*/
              font-size: 14px;
            } 
          }
    
          .input-el-a {
            padding: 5px;
            font-size: 14px;
            display: inline-block;
            /*color: green;*/
            margin-top: 10px;
            border: 2px solid grey;
            border-radius: 4px;
          }
        }

        .checkbox-opcije1 {
          height: 37px;
          width: 100%;
          margin: 0;
          
          .checkbox-redak1 {
            height: 35px;
            width: 100%;
            @include centriraj;

            .checkbox-div1 {
              margin: 0px 10px 0px 10px;
            }
          }
        }

        .el3-gumbi {
          height: 60px;
          width: 100%;
          @include centriraj;
      
          /*tu su bili div button selektori*/
        }
      }
  
      .el2 {
        height: 100px;
        width: 100%;
        @include centriraj;
        /*background-color: blue;*/
  
        .div-button {
          height: 40px;
          width: 150px;
          @include centriraj;
          background-color: #bbb;
          margin: 0px 20px 0px 20px;
          transition: all 0.7s;
          color: black;
          border-radius: 4px;
  
          &:hover {
            cursor: pointer;
            background-color: #666;
            color: white;
          }
        }
      }
    } 
   
    @media screen and (max-width: 840px) {
      & {
        flex-direction: column;
  
        .prometi-izbornik-lijeva {  
          width: 100%;  
          /*margin: 150px 0px 0px 0px;*/
        }
  
        .prometi-izbornik-desna {  
          width: 100%;  
          .buffer {
            height: 0;
          }
        }
      }
    }
}

.tablica-transakcija-cont {
    max-width: 100%;
    
    .tablica-transakcija {
      width: 500px;
      max-width: 95%;
      position: relative;
      margin: 0px auto 0px auto;
  
      .spinner {
        top: 30%;
        left: 30%;
      }
  
      tr:nth-child(odd) {
        background-color: $neparniRedak;
      }
  
      tr:nth-child(even) {
        background-color: $parniRedak;
      }
  
      tr.hover {
        background-color: grey;
        cursor: pointer;
      }
  
      .hover-klasa {
        &:hover {
          background-color: grey;
          cursor: pointer;
        }
      }
  
      .row {
        width: 100%;
        transition: background-color 0.3s;
        
        .row-el1 {
          width: 33.33%;
          height: 30px;
          border: 1px dotted black;
          padding: 0px 7px 0px 7px;
          overflow: visible;
        }
        .row-el2 {
          width: 66.66%;
          height: 30px;
          border: 1px dotted black;
          text-align: center;
          overflow: visible;

          .div-button {
            height: 30px;
            width: 80px;
            @include centriraj;
            background-color: #bbb;
            margin: 0px auto 0px auto;
            transition: all 0.7s;
            color: black;
            border-radius: 4px;
            user-select: none;
      
            &:hover {
              cursor: pointer;
              background-color: #64c5fa;
              color: white;
            }
          }
  
          .div-button1 {
            width: 100px;
          }
  
          .klik {
            background-color: red;
            transition: all 0s;
  
            &:hover {
              background-color: red;
            }
          }

          .kalendar-cont {
            min-height: 100%;
            width: 100%;
            @include centriraj;
            margin: 10px 0px 10px 0px;
            /*background-color: yellow;*/
          }

          .div-tjedan {
            min-height: 100px;
            width: 100%;
            @include centriraj;
          }

          .gumbi-1 {
            height: 100%;
            width: 100%;
            @include centriraj;
  
          .div-button-1 {
            height: 27px;
            width: 80px;
            font-size: 16px;
            @include centriraj;
            background-color: #bbb;
            margin: 0px 10px 0px 10px;
            transition: all 0.7s;
            color: black;
            border-radius: 4px;
            user-select: none;
      
            &:hover {
              cursor: pointer;
              background-color: #64c5fa;
              color: white;
            }
          }
  
          .klik {
            background-color: red;
            transition: all 0s;
  
            &:hover {
              background-color: red;
            }
          }

          }
        }

      
      }
    }
}

.popup-plast-confirm-transakcije {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  @include centriraj;

.popup-confirm-transakcije {
  height: 450px;
  width: 500px;
  max-width: 94vw;
  background-color: $crna1;
  margin: 100px auto 0px auto;
  position: relative;
  border: 1px solid black;

  .spinner {  // overridan spinner za slucaj signout windowa
    left: 200px;
    top: 240px;
  }    

  .naslov {
    font-size: 25px;
    font-family: sans-serif;
    font-weight: 600;
    color: white;
    width: 80%;
    margin: 130px auto 0px auto;
    text-align: center;
    /*background-color: yellow;*/
  }

  .gumbi {
    /*background-color: yellow;*/
    width: 100%;
    @include centriraj;
    flex-direction: row;
    margin-top: 50px;

    .gumb {  // overidamo za slucaj gumba na signout windowu
      margin: 0px 50px 0px 50px;
    
      .gumb-ikona {
        color: white;
        height: 0px;
        width: 0px;
        margin-right: 0px;
      }
    }
  }
}
}