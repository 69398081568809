@import 'zajednicki';

$podloga: #dcf9fa;

$parniRedak: #b3c9c9;
$neparniRedak: #95bdbd;
$crna1: black;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.holder {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $podloga;
}

@mixin centriraj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prometi-izbornik {
    min-height: 100vh;
    width: 100vw;
    background-color: $podloga;
    border: 1px solid $podloga;
    box-sizing: border-box;
    position: relative;
    /*@include centriraj;*/
    display: block;

    .tablica-prometi {
        width: 500px;
        max-width: 95%;
        position: relative;
        margin: 180px auto 30px auto;

        .spinner {
            top: 30%;
            left: 30%;
        }

        tr:nth-child(odd) {
            background-color: $neparniRedak;
        }

        tr:nth-child(even) {
            background-color: $parniRedak;
        }

        tr.hover {
            background-color: grey;
            cursor: pointer;
        }

        .hover-klasa {
            &:hover {
                background-color: grey;
                cursor: pointer;
            }
        }

        .row {
            width: 100%;
            transition: background-color 0.3s;

            .row-el1 {
                width: 33.33%;
                height: 30px;
                border: 1px dotted black;
                padding: 0px 7px 0px 7px;
                overflow: visible;
            }

            .row-el2 {
                width: 66.66%;
                height: 30px;
                border: 1px dotted black;
                text-align: center;
                overflow: visible;

                .gumbi-1 {
                  height: 100%;
                  width: 100%;
                  @include centriraj;
          
                  .div-button-1 {
                    height: 27px;
                    width: 80px;
                    font-size: 16px;
                    @include centriraj;
                    background-color: #bbb;
                    margin: 0px 10px 0px 10px;
                    transition: all 0.7s;
                    color: black;
                    border-radius: 4px;
                    user-select: none;
              
                    &:hover {
                      cursor: pointer;
                      background-color: #64c5fa;
                      color: white;
                    }
                  }
          
                  .klik {
                    background-color: red;
                    transition: all 0s;
          
                    &:hover {
                      background-color: red;
                    }
                  }
        
                }

                .div-button {
                    height: 30px;
                    width: 80px;
                    @include centriraj;
                    background-color: #bbb;
                    margin: 0px auto 0px auto;
                    transition: all 0.7s;
                    color: black;
                    border-radius: 4px;
                    user-select: none;
              
                    &:hover {
                      cursor: pointer;
                      background-color: #64c5fa;
                      color: white;
                    }
                }

                .prosireni {
                    width: 100px;
                }
            }
        }
    }
}