@import 'zajednicki';

$podloga: #dcf9fa;

$parniRedak: #b3c9c9;
$neparniRedak: #95bdbd;
$crna1: black;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.holder {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $podloga;
}

@mixin centriraj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prometi-izbornik-aa {
    min-height: 100vh;
    width: 100vw;
    background-color: $podloga;
    border: 1px solid $podloga;
    box-sizing: border-box;
    position: relative;
    @include centriraj;
    flex-direction: row;

    .div-button {
      height: 34px;
      width: 100px;
      @include centriraj;
      font-size: 14px;
      background-color: #bbb;
      margin: 0px 15px 0px 15px;
      transition: all 0.7s;
      color: black;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: #666;
        color: white;
      }
    }

    .klik {
      background-color: red;
      transition: all 0s;

      &:hover {
        background-color: red;
      }
    }
  
    .prometi-izbornik-desna {
      min-height: 100vh;
      width: 50%;
  
      .buffer {
        height: 150px;
        width: 100%;
      }

      .button-cont {
        height: 50px;
        width: 100%;
        @include centriraj;
      }
    }
  
    .prometi-izbornik-lijeva { 
      /*min-height: 100vh;*/
      width: 50%;
      box-sizing: border-box;
      position: relative;

      .antiklik-pokrivac {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        display: none;
      }
  
      .el {
        margin: 130px auto 0px auto;
        height: 100px;
        width: 100%;
        @include centriraj;
  
        .input-div {
          .input-div1 {
            /*background-color: red;*/
            font-size: 20px;
          } 
        }
  
        .input-el {
          padding: 5px;
          font-size: 19px;
          display: inline-block;
          /*color: green;*/
          margin-top: 10px;
          border: 2px solid grey;
          border-radius: 4px;
        }
      }
  
      .el1 {
        height: 180px;
        width: 100%;
        @include centriraj;
        flex-direction: column;
  
        .checkbox-opcije {
          height: 40px;
          width: 100%;
          @include centriraj;
  
          .checkbox-div {
            margin: 0px 10px 0px 10px;
          }
        }
  
        .izbornik {
          width: 300px;
        }

        .el1-gumbi {
            height: 60px;
            width: 100%;
            @include centriraj;
        
            /*tu su bili div button selektori*/
          }
      }
  
      .el2 {
        height: 100px;
        width: 100%;
        @include centriraj;
        /*background-color: blue;*/
  
        .div-button {
          height: 40px;
          width: 150px;
          @include centriraj;
          background-color: #bbb;
          margin: 0px 20px 0px 20px;
          transition: all 0.7s;
          color: black;
          border-radius: 4px;
  
          &:hover {
            cursor: pointer;
            background-color: #666;
            color: white;
          }
        }
      }
    } 
   
    @media screen and (max-width: 840px) {
      & {
        flex-direction: column;
  
        .prometi-izbornik-lijeva {  
          width: 100%;  
          /*margin: 150px 0px 0px 0px;*/
        }
  
        .prometi-izbornik-desna {  
          width: 100%;  
          .buffer {
            height: 0;
          }
        }
      }
    }
}

.tablica-prometi-cont {
    max-width: 100%;
    
    .tablica-prometi {
      width: 500px;
      max-width: 95%;
      position: relative;
      margin: 0px auto 0px auto;
  
      .spinner {
        top: 30%;
        left: 30%;
      }
  
      tr:nth-child(odd) {
        background-color: $neparniRedak;
      }
  
      tr:nth-child(even) {
        background-color: $parniRedak;
      }
  
      tr.hover {
        background-color: grey;
        cursor: pointer;
      }
  
      .hover-klasa {
        &:hover {
          background-color: grey;
          cursor: pointer;
        }
      }
  
      .row {
        width: 100%;
        transition: background-color 0.3s;
        
        .row-el1 {
          width: 33.33%;
          height: 30px;
          border: 1px dotted black;
          padding: 0px 7px 0px 7px;
          overflow: visible;
        }
        .row-el2 {
          width: 66.66%;
          height: 30px;
          border: 1px dotted black;
          text-align: center;
          overflow: visible;

          .div-tjedan {
            min-height: 100px;
            width: 100%;
            @include centriraj;
          }

          .gumbi-1 {
            height: 100%;
            width: 100%;
            @include centriraj;
  
          .div-button-1 {
            height: 27px;
            width: 80px;
            font-size: 16px;
            @include centriraj;
            background-color: #bbb;
            margin: 0px 10px 0px 10px;
            transition: all 0.7s;
            color: black;
            border-radius: 4px;
            user-select: none;
      
            &:hover {
              cursor: pointer;
              background-color: #64c5fa;
              color: white;
            }
          }
  
          .klik {
            background-color: red;
            transition: all 0s;
  
            &:hover {
              background-color: red;
            }
          }

          }
        }

        .row-el3 {
          @include centriraj;
  
          .div-button-3 {
            height: 30px;
            width: 80px;
            @include centriraj;
            background-color: #bbb;
            /*margin: 0px auto 0px auto;*/
            transition: all 0.7s;
            color: black;
            border-radius: 4px;
            user-select: none;
      
            &:hover {
              cursor: pointer;
              background-color: #64c5fa;
              color: white;
            }
          }
  
          #div19 {
            margin: 0px;
          }
    
          #input19a {
            width: 80px;
            margin: 10px 10px 10px 10px;
            /*color: yellow;
            background-color: red;*/
            display: block;
          }
    
          .klik {
            background-color: red;
            transition: all 0s;
    
            &:hover {
              background-color: red;
            }
          }
        }
      
      }
    }
}

.popup-plast-confirm-prometi {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    @include centriraj;
  
  .popup-confirm-prometi {
    height: 450px;
    width: 500px;
    max-width: 94vw;
    background-color: $crna1;
    margin: 100px auto 0px auto;
    position: relative;
    border: 1px solid black;
  
    .spinner {  // overridan spinner za slucaj signout windowa
      left: 200px;
      top: 240px;
    }    
  
    .naslov {
      font-size: 25px;
      font-family: sans-serif;
      font-weight: 600;
      color: white;
      width: 80%;
      margin: 130px auto 0px auto;
      text-align: center;
      /*background-color: yellow;*/
    }
  
    .gumbi {
      /*background-color: yellow;*/
      width: 100%;
      @include centriraj;
      flex-direction: row;
      margin-top: 50px;
  
      .gumb {  // overidamo za slucaj gumba na signout windowu
        margin: 0px 50px 0px 50px;
      
        .gumb-ikona {
          color: white;
          height: 0px;
          width: 0px;
          margin-right: 0px;
        }
      }
    }
  }
}

.automatski-unos-popup {
  height: 100vh;
  width: 100vw;
  @include centriraj;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  cursor: initial;

  .automatski-unos-okvir {
    width: 550px;
    height: 400px;
    max-width: 95vw;
    background-color: $podloga;
    border: 3px solid grey;
    position: relative;
    top: 100px; //privremeno
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .automatski-unos-gumbi {
      width: 100%;
      height: 10%;
      user-select: none;
      @include centriraj;

      .checkbox-opcije {
        height: 100%;
        width: 150px;
        @include centriraj;

        .checkbox-div {
          margin: 0px 10px 0px 10px;
        }

        @media screen and (max-width: 490px) {
          & {
            width: 120px;
            font-size: 12px;

            .checkbox-div {
              margin: 0px 7px 0px 7px;
            }
          }
        }
      }

      .div-button1 {
        height: 34px;
        width: 100px;
        @include centriraj;
        font-size: 14px;
        background-color: #bbb;
        margin: 0px 15px 0px 15px;
        transition: all 0.7s;
        color: black;
        border-radius: 4px;
  
        &:hover {
          cursor: pointer;
          background-color: #666;
          color: white;
        }

        @media screen and (max-width: 490px) {
          & {
            height: 27px;
            width: 65px;
            font-size: 11px;
            margin: 0px 7px 0px 7px;
          }
        }
      }
  
      .klik {
        background-color: red;
        transition: all 0s;
  
        &:hover {
          background-color: red;
        }
      }
    }

    .automatski-unos-textarea {
      font-size: 14px;
      height: 80%;
      width: 90%;
      background-color: white;
      border: 1px solid black;
      border-radius: 7px;

      .greska {
        /*background-color: red;*/
        margin: 0;
      }

      .Error1, .Error2, .Error3, .Error4 {
        color: red;
      }

      .Error5 {
        color: blueviolet;
      }

      .Error6 {
        color: blueviolet;
      }
    }

    .krizic {
      height: 30px;
      width: 30px;
      @include centriraj;
      position: absolute;
      right: 10px;
      top: 10px;
      /*z-index: 10;*/
      background-color: white;
      border-radius: 50%;
      transition: all 0.4s;

      .krizic-el {
        height: 60%;
        width: 60%;
        transform: rotate(45deg);
        
        .el1a {
          position: absolute;
          height: 22%;
          width: 100%;
          top: 39%;
          left: 0%;
          background-color: black;
          transition: all 0.4s;
        }
  
        .el2a {
          position: absolute;
          height: 100%;
          width: 22%;
          top: 0%;
          left: 39%;
          background-color: black;
          transition: all 0.4s;
        }
  
      }

      &:hover {
        cursor: pointer;
        background-color: #ccc;

        .krizic-el {
          .el1a {
            background-color: #026270;
          }
          .el2a {
            background-color: #026270;
          }
        }
      }
    }
  }
}