@import 'zajednicki';

$podloga: #dcf9fa;

$parniRedak: #b3c9c9;
$neparniRedak: #95bdbd;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tablica-cont-vozila {
  max-width: 100%;

  .tablica {
    width: 500px;
    position: relative;
    max-width: 95%;
    margin: 0px auto 0px auto;

    .spinner {
      top: 30%;
      left: 30%;
    }

    tr:nth-child(odd) {
      background-color: $neparniRedak;
    }

    tr:nth-child(even) {
      background-color: $parniRedak;
    }

    .hover {
      background-color: grey;
      cursor: pointer;
    }

    .row {
      width: 100%;
      transition: background-color 0.3s;
      
      .row-el1 {
        width: 33.33%;
        height: 30px;
        border: 1px dotted black;
        padding: 0px 7px 0px 7px;
        overflow: visible;
      }
      .row-el2 {
        width: 66.66%;
        height: 30px;
        border: 1px dotted black;
        text-align: center;
        overflow: visible;

        .kalendar-cont {
          min-height: 100%;
          width: 100%;
          @include centriraj;
          /*background-color: yellow;*/
        }

        .div-button {
          height: 30px;
          width: 80px;
          @include centriraj;
          background-color: #bbb;
          margin: 0px auto 0px auto;
          transition: all 0.7s;
          color: black;
          border-radius: 4px;
          user-select: none;
    
          &:hover {
            cursor: pointer;
            background-color: #64c5fa;
            color: white;
          }
        }

        .div-button1 {
          width: 100px;
        }

        .klik {
          background-color: red;
          transition: all 0s;

          &:hover {
            background-color: red;
          }
        }
      }
    }
  }
}

.pridruzi-vozilo-popup {
  height: 100vh;
  width: 100vw;
  @include centriraj;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0,0,0,0.6);
  cursor: initial;

  .okvir-menu {
    width: 400px;
    height: 400px;
    max-width: 95vw;
    background-color: $podloga;
    border: 3px solid grey;
    position: relative;

    .spinner {
      z-index: 6;
    }

    .gumbi {
      /*background-color: red;*/
      width: 100%;
      height: 40px;
      @include centriraj;


      .div-button-1 {
        height: 30px;
        width: 110px;
        @include centriraj;
        background-color: #bbb;
        margin: 0px 20px 20px 20px;
        transition: all 0.7s;
        color: black;
        border-radius: 4px;
        user-select: none;
  
        &:hover {
          cursor: pointer;
          background-color: #64c5fa;
          color: white;
        }
      }

      .div-button-2 {
        height: 30px;
        width: 110px;
        @include centriraj;
        font-size: 13px;
        background-color: #bbb;
        margin: 0px 20px 20px 20px;
        transition: all 0.7s;
        color: black;
        border-radius: 4px;
        user-select: none;
  
        &:hover {
          cursor: pointer;
          background-color: #64c5fa;
          color: white;
        }
      }

      .klik-1 {
        background-color: red;
        transition: all 0s;

        &:hover {
          background-color: red;
        }
      }

    }

    .krizic {
      height: 30px;
      width: 30px;
      @include centriraj;
      position: absolute;
      right: 10px;
      top: 10px;
      /*z-index: 10;*/
      background-color: white;
      border-radius: 50%;
      transition: all 0.4s;

      .krizic-el {
        height: 60%;
        width: 60%;
        transform: rotate(45deg);
        
        .el1a {
          position: absolute;
          height: 22%;
          width: 100%;
          top: 39%;
          left: 0%;
          background-color: black;
          transition: all 0.4s;
        }
  
        .el2a {
          position: absolute;
          height: 100%;
          width: 22%;
          top: 0%;
          left: 39%;
          background-color: black;
          transition: all 0.4s;
        }
  
      }

      &:hover {
        cursor: pointer;
        background-color: #ccc;

        .krizic-el {
          .el1a {
            background-color: #026270;
          }
          .el2a {
            background-color: #026270;
          }
        }
      }
    }

    .tablica {
      width: 300px;
      max-width: 90%;
      margin: 10px auto 0px auto;

      .row {
        width: 100%;
        transition: background-color 0.3s;
        
        .row-el1 {
          width: 55%;
          height: 30px;
          border: 1px dotted black;
          padding: 0px 7px 0px 7px;
          overflow: visible;
        }
        .row-el2 {
          width: 44%;
          height: 30px;
          border: 1px dotted black;
          text-align: center;
          overflow: visible;
        }
      }
    }
    .kalendar-cont {
      width: 100%;
      /*background-color: yellow;*/
      display: flex;
      flex-direction: row;

      .lijeva-strana {
        /*background-color: pink;*/
        height: 100%;
        width: 200px;

        .el-a {
          margin: 0px auto 0px auto;
          height: 75px;
          width: 100%;
          /*background-color: yellow;
          border: 2px solid black;*/
          @include centriraj;
      
          .input-div-a {
            .input-div1-a {
              /*background-color: red;*/
              font-size: 15px;
            } 
          }
      
          .input-el-a {
              padding: 5px;
              font-size: 15px;
              display: inline-block;
              /*color: green;*/
              margin-top: 10px;
              border: 2px solid grey;
              border-radius: 4px;
              max-width: 150px;
            }
        }
      
        .el1-a {
          height: 120px;
          width: 100%;
          /*background-color: green;*/
          @include centriraj;
      
          .izbornik-a {
            width: 190px;
            font-size: 11px;
            
            .span-el-a {
              display: inline-block;
              min-width: 40px;
            }
          }
          .no-click {
            pointer-events: none;
          }
      
        }
      }
    }
  }
}

.povijest-vozila {
  height: 170px;
  width: 100%;
  /*background-color: pink;*/

  .el1-a1 {
    height: 100%;
    width: 100%;
    /*background-color: yellow;*/
    /*@include centriraj;*/
    flex-direction: column;

    .izbornik-a1 {
      width: 300px;
      max-width: 98%;
      font-size: 10px;
      margin: 20px auto 0px auto;
      
      .span-el-a1 {
        display: inline-block;
        min-width: 60px;
      }
    }

    .povijest-gumbi {
      height: 40px;
      width: 100%;
      @include centriraj;

    .div-button-1 {
      height: 30px;
      width: 110px;
      @include centriraj;
      background-color: #bbb;
      margin: 0px 15px 0px 15px;;
      transition: all 0.7s;
      color: black;
      border-radius: 4px;
      user-select: none;

      &:hover {
        cursor: pointer;
        background-color: #64c5fa;
        color: white;
      }
    }

    .klik-1 {
      background-color: red;
      transition: all 0s;

      &:hover {
        background-color: red;
      }
    }

    }
  }
}

.tablica-cont {
  /*width: 100vw;
  min-height: 700px;*/
  max-width: 100%;
  

  .tablica {
    width: 500px;
    max-width: 95%;
    position: relative;
    margin: 0px auto 0px auto;

    .spinner {
      top: 30%;
      left: 30%;
    }

    tr:nth-child(odd) {
      background-color: $neparniRedak;
    }

    tr:nth-child(even) {
      background-color: $parniRedak;
    }

    tr.hover {
      background-color: grey;
      cursor: pointer;
    }

    .hover-klasa {
      &:hover {
        background-color: grey;
        cursor: pointer;
      }
    }

    .row {
      width: 100%;
      transition: background-color 0.3s;
      
      .row-el1 {
        width: 33.33%;
        height: 30px;
        border: 1px dotted black;
        padding: 0px 7px 0px 7px;
        overflow: visible;
      }
      .row-el2 {
        width: 66.66%;
        height: 30px;
        border: 1px dotted black;
        text-align: center;
        overflow: visible;
        position: relative;

        .lozinka {
          max-width: 150px;
          margin: 5px 10px 5px 10px;
        }

        .lozinka-label {
          width: 80px;
          font-size: 12px;
          display: inline-block;
        }

        .div-button {
          height: 30px;
          width: 80px;
          @include centriraj;
          background-color: #bbb;
          margin: 0px auto 0px auto;
          transition: all 0.7s;
          color: black;
          border-radius: 4px;
          user-select: none;
    
          &:hover {
            cursor: pointer;
            background-color: #64c5fa;
            color: white;
          }
        }

        .klik {
          background-color: red;
          transition: all 0s;

          &:hover {
            background-color: red;
          }
        }
      }

      .row-el3 {
        @include centriraj;

        .div-button-3 {
          height: 30px;
          width: 80px;
          @include centriraj;
          background-color: #bbb;
          /*margin: 0px auto 0px auto;*/
          transition: all 0.7s;
          color: black;
          border-radius: 4px;
          user-select: none;
    
          &:hover {
            cursor: pointer;
            background-color: #64c5fa;
            color: white;
          }
        }

          #div19 {
            margin: 0px;
          }
  
          #input19a {
            width: 80px;
            margin: 10px 10px 10px 10px;
            /*color: yellow;
            background-color: red;*/
            display: block;
          }
  
          .klik {
            background-color: red;
            transition: all 0s;
  
            &:hover {
              background-color: red;
            }
          }
        
      }

      .poravnaj {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.lockk {
  position: absolute;
  top: 3px;
  right: 5px;
  height: 20px;
  width: 20px;
  @include centriraj;

  .lock-ikona {
    height: 80%;
    width: 80%;
    color: rgb(4, 82, 4);
    transition: 0.7s all;
  }

  .unlock-ikona {
    height: 80%;
    width: 80%;
    color: rgb(150, 14, 14);
    transition: 0.7s all;
  }

  &:hover {
    .lock-ikona {
      color: green;
    }

    .unlock-ikona {
      color: red;
    }
  }
}

.display-none {
  display: none;
}