@import 'zajednicki';

$crna1: black;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.alias-manager {
  height: 250px;
  width: 100%;

  .izbornik {
    width: 250px;
    max-width: 95%;
    margin: 5px auto 0px auto;
  }

  .alias-el {
    width: 100%;
    height: 60px;

    .checkbox-opcije {
      @include centriraj;
      margin: 5px 0px 5px 0px;

      .checkbox-div {
        margin: 0px 10px 0px 10px;
      }
    }

    .input-alias {
      font-size: 18px;
      max-width: 95%;
    }
  }

  .alias-gumbi {
    height: 50px;
    width: 100%;
    @include centriraj;

    .div-alias-button {
      height: 30px;
      width: 90px;
      @include centriraj;
      background-color: #bbb;
      margin: 0px 15px 0px 15px;
      transition: all 0.7s;
      color: black;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
        background-color: #666;
        color: white;
      }
    }
  }
}

.popup-plast-confirm-alias {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  @include centriraj;

.popup-confirm-alias {
  height: 450px;
  width: 500px;
  max-width: 94vw;
  background-color: $crna1;
  margin: 100px auto 0px auto;
  position: relative;
  border: 1px solid black;

  .spinner {  // overridan spinner za slucaj signout windowa
    left: 200px;
    top: 240px;
  }    

  .naslov {
    font-size: 25px;
    font-family: sans-serif;
    font-weight: 600;
    color: white;
    width: 80%;
    margin: 130px auto 0px auto;
    text-align: center;
    /*background-color: yellow;*/
  }

  .gumbi {
    /*background-color: yellow;*/
    width: 100%;
    @include centriraj;
    flex-direction: row;
    margin-top: 50px;

    .gumb {  // overidamo za slucaj gumba na signout windowu
      margin: 0px 50px 0px 50px;
    
      .gumb-ikona {
        color: white;
        height: 0px;
        width: 0px;
        margin-right: 0px;
      }
    }
  }
}
}